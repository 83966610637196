import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import PubSub from 'pubsub-js';
import axios from 'axios';
import queryString from 'query-string';

export default class ProcessButton extends Component {
  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (prevProps.user !== user) {
      if (user.isLoggedIn) this.process();
    }
  }

  process = () => {
    const { bookingId, billId, user } = this.props;

    if (!user.isLoggedIn()) {
      PubSub.publish('Devise.SignIn', { autoProcess: true, modalTitle: 'Sign In' });
      return true;
    }

    const query = queryString.parse(location.search);
    const data = { booking_user_id: query.user_id };

    axios
      .post(`/bookings/${bookingId}/bills/${billId}/prepare`, data)
      .then((response) => {
        window.location = `/bookings/${response.data.data.booking_id}/checkout`;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    return (
      <Button bsStyle="primary" onClick={this.process}>
        PROCEED TO CHECKOUT
      </Button>
    );
  }
}