import React, { Component, Fragment } from 'react';
import Activities from '../booking/activities';
import BookingOptions from '../booking/booking_options';
import axios from 'axios';
import { Alert, Button } from 'react-bootstrap';
import CalendarWrapper from "../booking/calendar_wrapper";

export default class Index extends Component {
  constructor() {
    super();

    this.state = {
      venue: null,
      activities: [],
      bookingOptions: [],
      bill: null,
      venueId: null,
      activityCategory: null,
      activity: null,
      bookingOption: null,
      bookingOptionItem: null,
      dates: [],
      scheduleItemIds: [],
      formErrors: null,
      pendingUser: false
    };
  }

  componentDidMount() {
    const { venue, bookingItem } = this.props;
    const { activity_categories, activities } = venue;

    const activityCategory = activity_categories.find((item) => item.id === bookingItem.activity_category_id);
    const activity = activities.find((item) => item.id === bookingItem.activity_id);

    this.setState({ venue, activityCategory, activity }, () => {
      this.fetchBookingOption(bookingItem.booking_option_id)
    });
  }

  fetchActivity(activityId) {
    axios
      .get(`/activities/${activityId}.json`)
      .then(response => this.setState({ activity: response.data.data }));
  }

  fetchBookingOption(bookingOptionId, onSuccess) {
    if (isNaN(bookingOptionId)) return null;

    axios
      .get(`/booking_options/${bookingOptionId}.json`)
      .then(response => this.setState({ bookingOption: response.data }, () => {
        if (typeof onSuccess === 'function') onSuccess();
      }))
      .catch(e => console.log(e));
  }

  getFilteredActivities = (activityCategory) => {
    const { venue } = this.props;
    let { activities } = venue;

    if (activityCategory) {
      activities = activities.filter(activity => activity.activity_category_id === activityCategory.id);
    }

    return activities;
  };

  changeActivity = (activity) => {
    this.setState({ activity: activity, bookingOption: null, bookingOptions: [] }, () => {
      this.fetchActivity(activity.id)
    });
  };

  changeBookingOption = (bookingOption) => {
    this.fetchBookingOption(bookingOption.id, () => {
      this.setState({ bookingOption });
    });
  };

  handleUpdateBookingOptionItem = (bookingOptionItem, dates, scheduleItemIds) => {
    const { updateDatesCount } = this.props;
    this.setState({ bookingOptionItem, dates, scheduleItemIds }, () => {
      if (updateDatesCount) updateDatesCount(dates.length);
    });
  };

  formError(name) {
    const { formErrors } = this.state;
    if (formErrors === null) return null;
    if (typeof formErrors !== 'object') return null;
    return (name in formErrors) ? formErrors[name] : null;
  }

  updateBookingItem = (successCallback, failedCallback) => {
    let {
      activity,
      bookingOption,
      bookingOptionItem,
      dates,
      scheduleItemIds
    } = this.state;

    let { bookingItem } = this.props;
    let { id } = bookingItem;

    activity = (activity || {});

    let data = {
      booking_item: {
        activity_id: (activity || {}).id,
        booking_option_id: (bookingOption || {}).id,
        booking_option_item_id: (bookingOptionItem || {}).id,
        dates: dates,
        schedule_item_ids: scheduleItemIds,
        formErrors: [],
        attendeeErrors: []
      }
    };

    axios
      .put(`/admin/booking_items/${id}.json`, data)
      .then(() => {
        this.setState({ formErrors: [] });
        if (successCallback) successCallback();
      })
      .catch((error) => {
        const { errors } = error.response.data;
        this.setState({ formErrors: errors });
        if (failedCallback) failedCallback();
      });
  };

  render() {
    const {
      activityCategory,
      activity,
      bookingOption,
    } = this.state;

    console.log('render');

    const { bookingItem } = this.props;
    const bookingOptionItemId = bookingItem.booking_option_item_id;
    const eventDates = bookingItem.schedule_items.map(item => item.event_date);
    const activities = this.getFilteredActivities(activityCategory);
    const datesErrors = this.formError('dates');

    return (
      <div className="generator-panel">
        <Activities
          activities={activities}
          onChange={this.changeActivity}
          activity={activity}
        />

        {activity &&
        <Fragment>
          <BookingOptions
            bookingOptions={activity.booking_options.data}
            selectedBookingOptionId={(bookingOption || {}).id}
            onChange={this.changeBookingOption}
          />

          {bookingOption &&
          <CalendarWrapper
            bookingOption={bookingOption}
            selectedBookingOptionItemId={bookingOptionItemId}
            selectedEventDates={eventDates}
            updateBookingOptionItem={this.handleUpdateBookingOptionItem}
          />
          }

          {datesErrors &&
          <Alert bsStyle="danger">
            {datesErrors.map((error) => <div>{error}</div>)}
          </Alert>
          }
        </Fragment>
        }
      </div>
    )
  }
}
