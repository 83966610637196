import FieldGroup from "../../../smart/field_group";
import React from "react";

const OfflineCheckbox = ({ onChange }) => (
  <FieldGroup
    type="checkbox"
    placeholder='Offline orders'
    name="only_offline[]"
    defaultValue={false}
    onChange={onChange}
    singleInput={true}
  />
)

export default ({ onChange }) => (
  <FieldGroup
    label="Statuses:"
    type="checkbox-group"
    name="statuses"
    options={[
      { value: "accepted", title: "Accepted", defaultChecked: true },
      { value: "cancelled", title: "Cancelled", defaultChecked: true },
      { value: "completed", title: "Completed", defaultChecked: true }
    ]}
    onChange={onChange}
    afterLabel={<OfflineCheckbox onChange={onChange}/>}
  />
)
